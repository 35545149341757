// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import TableOfContents from './toc'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- Query
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img: file(relativePath: { eq: "cover.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const CoverPage = React.memo(({ lang }) => (
  <StaticQuery
    query={query}
    render={(data) => (
      <Fragment>
        <div>
          <div>
            <h2>Sri Aurobindo And Mother</h2>
            <h1>FINDING THE PYSCHIC BEING</h1>
          </div>
          <div>
            <GatsbyImage
              image={getImage(data.img)}
              objectPosition="center top"
            />
          </div>
          <div>
            <div>
              <p>SOUL IN THE IGNORANCE</p>
              <p>
                Soul in the Ignorance, wake from its stupor.
                <br />
                Flake of the world-fire, spark of Divinity,
                <br />
                Lift up thy mind and thy heart into glory.
                <br />
                Sun in the darkness, recover thy lustre.
              </p>
              <p>
                One, universal, ensphering creation,
                <br />
                Wheeling no more with inconscient Nature,
                <br />
                Feel thyself God-born, know thyself deathless. Timeless return
                to thy immortal existence.
              </p>
              <p>Sri Aurobindo</p>
            </div>
          </div>
        </div>
        <div>
          <div>&nbsp;</div>
          <div>
            <p className="dropcap">
              <span>T</span>he word “Psychic” comes from “Psyche”, the Greek
              word for the soul. Sri Aurobindo and Mother used it to refer to
              the individual soul in order not to confuse it with the great
              Soul. People who practice Sri Aurobindo’s Yoga are told that their
              life and their yoga practice will improve when they find their
              Psychic Being and realize their soul. This is the first stage in
              his Yoga. Above our head is our Jivatman. The word Jivatman is
              made up of two Sanskrit words; Jiva, the individual person, and
              Atman, the great Soul. It is uncreated, undying and unborn. Our
              Jivatman puts a portion of itself into our heart to be our
              individual soul. It goes through birth, death and rebirth. Because
              our soul is a pure part of the Supreme Consciousness, it is always
              conscious of its Divine origin. This is what inspires and guides
              us to grow in consciousness until all of our being is conscious of
              the Divine.
            </p>
            <p>
              The Psychic Being at its origin is only a spark of the divine
              consciousness in our soul. Our soul contains all the highest
              qualities, like love, kindness, tenderness, goodness, wisdom,
              patience, tolerance, generosity, etc. Over innumerable lifetimes,
              our soul forms and organizes our Psychic Being around itself by
              putting its high divine qualities into our more outer parts, our
              mind, vital and physical. Then they are “psychisized”. Eventually
              all our psychisized parts are vibrating at the same rate and
              cooperating in the same way. This makes an organized, integrated
              inner being called our Psychic Being.
            </p>
            <TableOfContents lang={lang} />
          </div>
          <div>&nbsp;</div>
        </div>
      </Fragment>
    )}
  />
))

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default CoverPage
